import { type DependencyList, useEffect } from 'react'
import { ScrollToBottom } from '../../utilities/ScrollToBottom'

const useScrollElToBottom = ({
  elSelector,
  delay,
  dependencies,
}: {
  elSelector: string
  delay: number
  dependencies: DependencyList
}): void => {
  useEffect(() => {
    const element = document.querySelector(elSelector)
    if (element) {
      setTimeout(() => {
        ScrollToBottom(element)
      }, delay)
    }
  }, dependencies)
}

export default useScrollElToBottom
