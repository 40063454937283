import { type PayloadAction } from '@reduxjs/toolkit'
import {
  type IChatHistoryItemOptional,
  type IChatHistoryItem,
} from '../../../features/chatbot/chats-history/IChatsHistory'
import { initialActiveChatState } from '../../../features/chatbot/active-chat/ActiveChatSlice'

export const ActiveChatReducers = {
  setActiveChat: (
    state: IChatHistoryItem,
    action: PayloadAction<IChatHistoryItemOptional>,
  ) => {
    return { ...state, ...action.payload }
  },

  clearActiveChat: () => {
    return initialActiveChatState
  },
}
