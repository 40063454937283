import { createSlice } from '@reduxjs/toolkit'
import { type ILateralMenu } from './ILateralMenu'
import { lateralMenuReducer } from '../../reducers/lateral-menu/LateralMenuReducers'

const initialState: ILateralMenu = {
  isOpen: true,
}

const lateralMenuSlice = createSlice({
  name: 'lateralMenu',
  initialState,
  reducers: lateralMenuReducer,
})

export const { toggleLateralMenu } = lateralMenuSlice.actions
export default lateralMenuSlice.reducer
