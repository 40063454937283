import { createSlice } from '@reduxjs/toolkit'
import { type IToast } from '../../../core/components/toast/interfaces/IToast'
import { ToastReducers } from '../../reducers/toast/ToastReducers'
import { ToastTypes } from '../../../core/components/toast/enum/ToastTypes'

const initialState: IToast = {
  type: ToastTypes.INFORMATION,
  description: '',
  isVisible: false,
  timeToClose: 0,
}

const toastSlice = createSlice({
  initialState,
  name: 'toast',
  reducers: ToastReducers,
})

export const { showToast, closeToast } = toastSlice.actions
export default toastSlice.reducer
