import { type Observable, Subject } from 'rxjs'

export class SubjectManager {
  subject$ = new Subject()

  getSubject(): Observable<any> {
    return this.subject$.asObservable()
  }

  setSubject(value: any): void {
    this.subject$.next(value)
  }
}
