import React, { lazy, Suspense } from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import { PUBLIC_ROUTES } from './Routes'
import { RolType } from './app/core/enums/RolType'
import ErrorBoundary from './ErrorBoundary'

// Dashboard
const AsyncDashboardRouterOutlet = lazy(
  async () =>
    await import(
      './app/modules/dashboard/pages/router-outlets/DashboardRouterOutlet'
    ),
)

// Auth
const AsyncAuthRouterOutlet = React.lazy(
  async () => await import('./app/modules/auth/pages/router/AuthRootOutlet'),
)
const AsyncLogout = React.lazy(
  async () => await import('./app/modules/auth/pages/logout/LogoutPage'),
)
const AsyncLoginRouter = React.lazy(
  async () => await import('./app/modules/auth/pages/login/Login'),
)

// Pages

// Home
const AsyncHome = React.lazy(
  async () => await import('./app/modules/dashboard/pages/home/Home'),
)
// Library
const AsyncLibrary = React.lazy(
  async () => await import('./app/modules/dashboard/pages/library/Library'),
)
// MyDocs
const AsyncMyDocuments = React.lazy(
  async () =>
    await import('./app/modules/dashboard/pages/my-documents/MyDocuments'),
)
// Transcriptions
const AsyncTranscription = React.lazy(
  async () =>
    await import('./app/modules/dashboard/pages/transcriptions/Transcriptions'),
)
const AsyncTranscriptionDetail = React.lazy(
  async () =>
    await import(
      './app/modules/dashboard/pages/transcriptions/detail/TranscriptionDetail'
    ),
)
// Email validation
const AsyncEmailValidationRouterOutlet = React.lazy(
  async () =>
    await import(
      './app/modules/dashboard/pages/router-outlets/EmailValidationRouterOutlet'
    ),
)
const AsyncValidateEmail = React.lazy(
  async () =>
    await import(
      './app/modules/dashboard/pages/email-validation/validate-email/ValidateEmail'
    ),
)
const AsyncEmailVerified = React.lazy(
  async () =>
    await import(
      './app/modules/dashboard/pages/email-validation/email-verified/EmailVerified'
    ),
)
// Registration
const AsyncRegistrationRouterOutlet = React.lazy(
  async () =>
    await import(
      './app/modules/dashboard/pages/router-outlets/RegistrationRouterOutlet'
    ),
)
const AsyncCreateProfile = React.lazy(
  async () =>
    await import(
      './app/modules/dashboard/pages/registration/create-profile/CreateProfile'
    ),
)
const AsyncCreateCompany = React.lazy(
  async () =>
    await import(
      './app/modules/dashboard/pages/registration/create-company/CreateCompany'
    ),
)
const AsyncPricing = React.lazy(
  async () =>
    await import('./app/modules/dashboard/pages/registration/pricing/Pricing'),
)
const AsyncInvite = React.lazy(
  async () =>
    await import('./app/modules/dashboard/pages/registration/invite/Invite'),
)
const AsyncCompleted = React.lazy(
  async () =>
    await import(
      './app/modules/dashboard/pages/registration/completed/Completed'
    ),
)
// Chat
const AsyncConsultationsMobile = React.lazy(
  async () =>
    await import(
      './app/modules/dashboard/pages/chat/consultation/mobile/ConsultationsMobile'
    ),
)
const AsyncCreateConsultation = React.lazy(
  async () =>
    await import(
      './app/modules/dashboard/pages/chat/consultation/create/CreateConsultation'
    ),
)

const AsyncDetailConsultation = React.lazy(
  async () =>
    await import(
      './app/modules/dashboard/pages/chat/consultation/detail/DetailConsultation'
    ),
)

const AsyncChatIframe = React.lazy(
  async () =>
    await import('./app/modules/dashboard/pages/chat/chat-iframe/ChatIframe'),
)

export const ROUTER = createBrowserRouter([
  {
    path: '*',
    element: <div>Not found</div>,
  },
  {
    path: PUBLIC_ROUTES.AUTH.ROOT,
    element: (
      <React.Suspense fallback={<>...</>}>
        <ErrorBoundary>
          <AsyncAuthRouterOutlet />
        </ErrorBoundary>
      </React.Suspense>
    ),
    children: [
      {
        index: true,
        path: PUBLIC_ROUTES.AUTH.CHILD.LOGIN,
        element: (
          <React.Suspense fallback={<>...</>}>
            <ErrorBoundary>
              <AsyncLoginRouter />
            </ErrorBoundary>
          </React.Suspense>
        ),
      },
      {
        path: PUBLIC_ROUTES.AUTH.CHILD.LOGOUT,
        element: (
          <React.Suspense fallback={<>...</>}>
            <ErrorBoundary>
              <AsyncLogout />
            </ErrorBoundary>
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: '/',
    element: (
      <Suspense fallback={<>...</>}>
        <ErrorBoundary>
          <AsyncDashboardRouterOutlet
            allowedRoles={[RolType.USER, RolType.ADMIN, RolType.ROOT]}
          />
        </ErrorBoundary>
      </Suspense>
    ),
    children: [
      {
        index: true,
        path: PUBLIC_ROUTES.HOME,
        element: (
          <Suspense fallback={<>...</>}>
            <ErrorBoundary>
              <AsyncHome />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        index: true,
        path: PUBLIC_ROUTES.LIBRARY,
        element: (
          <Suspense fallback={<>...</>}>
            <ErrorBoundary>
              <AsyncLibrary />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        index: true,
        path: PUBLIC_ROUTES.MY_DOCUMENTS,
        element: (
          <Suspense fallback={<>...</>}>
            <ErrorBoundary>
              <AsyncMyDocuments />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        index: true,
        path: PUBLIC_ROUTES.TRANSCRIPTIONS.ROOT,
        element: (
          <Suspense fallback={<>...</>}>
            <ErrorBoundary>
              <AsyncTranscription />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        index: true,
        path: `${PUBLIC_ROUTES.TRANSCRIPTIONS.ROOT}/${PUBLIC_ROUTES.TRANSCRIPTIONS.CHILD.DETAIL}/:uuid`,
        element: (
          <Suspense fallback={<>...</>}>
            <ErrorBoundary>
              <AsyncTranscriptionDetail />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        index: true,
        path: `${PUBLIC_ROUTES.CONSULTATION.ROOT}/${PUBLIC_ROUTES.CONSULTATION.CHILDREN.CREATE}`,
        element: (
          <Suspense fallback={<>...</>}>
            <ErrorBoundary>
              <AsyncCreateConsultation />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        index: true,
        path: `${PUBLIC_ROUTES.CONSULTATION.ROOT}/${PUBLIC_ROUTES.CONSULTATION.CHILDREN.MOBILE}`,
        element: (
          <Suspense fallback={<>...</>}>
            <ErrorBoundary>
              <AsyncConsultationsMobile />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        index: true,
        path: `${PUBLIC_ROUTES.CONSULTATION.ROOT}/${PUBLIC_ROUTES.CONSULTATION.CHILDREN.DETAIL}/:referenceIdentifier`,
        element: (
          <Suspense fallback={<></>}>
            <ErrorBoundary>
              <AsyncDetailConsultation />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        index: true,
        path: `${PUBLIC_ROUTES.CONSULTATION.ROOT}/${PUBLIC_ROUTES.CONSULTATION.CHILDREN.TEMP}/:referenceIdentifier`,
        element: (
          <Suspense fallback={<></>}>
            <ErrorBoundary>
              <AsyncDetailConsultation />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        index: true,
        path: `${PUBLIC_ROUTES.CHAT_IFRAME}`,
        element: (
          <Suspense fallback={<>...</>}>
            <ErrorBoundary>
              <AsyncChatIframe />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        path: `${PUBLIC_ROUTES.OLD_ROUTES.CHAT}`,
        element: (
          <Navigate
            to={`/${PUBLIC_ROUTES.CONSULTATION.ROOT}/${PUBLIC_ROUTES.CONSULTATION.CHILDREN.CREATE}`}
            replace
          />
        ),
      },
      {
        path: `${PUBLIC_ROUTES.OLD_ROUTES.CREATE_CONSULTATION}`,
        element: (
          <Navigate
            to={`/${PUBLIC_ROUTES.CONSULTATION.ROOT}/${PUBLIC_ROUTES.CONSULTATION.CHILDREN.CREATE}`}
            replace
          />
        ),
      },
      {
        path: `${PUBLIC_ROUTES.OLD_ROUTES.CONSULTATIONS_MOBILE}`,
        element: (
          <Navigate
            to={`/${PUBLIC_ROUTES.CONSULTATION.ROOT}/${PUBLIC_ROUTES.CONSULTATION.CHILDREN.MOBILE}`}
            replace
          />
        ),
      },
    ],
  },
  {
    path: `/${PUBLIC_ROUTES.EMAIL_VALIDATION.ROOT}`,
    element: (
      <Suspense fallback={<>...</>}>
        <ErrorBoundary>
          <AsyncEmailValidationRouterOutlet
            allowedRoles={[RolType.USER, RolType.ADMIN, RolType.ROOT]}
          />
        </ErrorBoundary>
      </Suspense>
    ),
    children: [
      {
        index: true,
        path: `/${PUBLIC_ROUTES.EMAIL_VALIDATION.ROOT}/${PUBLIC_ROUTES.EMAIL_VALIDATION.CHILDREN.VALIDATE_EMAIL}`,
        element: (
          <Suspense fallback={<>...</>}>
            <ErrorBoundary>
              <AsyncValidateEmail />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        index: true,
        path: `/${PUBLIC_ROUTES.EMAIL_VALIDATION.ROOT}/${PUBLIC_ROUTES.EMAIL_VALIDATION.CHILDREN.EMAIL_VERIFIED}`,
        element: (
          <Suspense fallback={<>...</>}>
            <ErrorBoundary>
              <AsyncEmailVerified />
            </ErrorBoundary>
          </Suspense>
        ),
      },
    ],
  },
  {
    path: `/${PUBLIC_ROUTES.REGISTRATION.ROOT}`,
    element: (
      <Suspense fallback={<>...</>}>
        <ErrorBoundary>
          <AsyncRegistrationRouterOutlet
            allowedRoles={[RolType.USER, RolType.ADMIN, RolType.ROOT]}
          />
        </ErrorBoundary>
      </Suspense>
    ),
    children: [
      {
        index: true,
        path: `/${PUBLIC_ROUTES.REGISTRATION.ROOT}/${PUBLIC_ROUTES.REGISTRATION.CHILDREN.PROFILE}`,
        element: (
          <Suspense fallback={<>...</>}>
            <ErrorBoundary>
              <AsyncCreateProfile />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        index: true,
        path: `/${PUBLIC_ROUTES.REGISTRATION.ROOT}/${PUBLIC_ROUTES.REGISTRATION.CHILDREN.COMPANY}`,
        element: (
          <Suspense fallback={<>...</>}>
            <ErrorBoundary>
              <AsyncCreateCompany />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        index: true,
        path: `/${PUBLIC_ROUTES.REGISTRATION.ROOT}/${PUBLIC_ROUTES.REGISTRATION.CHILDREN.PRICING}`,
        element: (
          <Suspense fallback={<>...</>}>
            <ErrorBoundary>
              <AsyncPricing />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        index: true,
        path: `/${PUBLIC_ROUTES.REGISTRATION.ROOT}/${PUBLIC_ROUTES.REGISTRATION.CHILDREN.INVITE}`,
        element: (
          <Suspense fallback={<>...</>}>
            <ErrorBoundary>
              <AsyncInvite />
            </ErrorBoundary>
          </Suspense>
        ),
      },
      {
        index: true,
        path: `/${PUBLIC_ROUTES.REGISTRATION.ROOT}/${PUBLIC_ROUTES.REGISTRATION.CHILDREN.COMPLETED}`,
        element: (
          <Suspense fallback={<>...</>}>
            <ErrorBoundary>
              <AsyncCompleted />
            </ErrorBoundary>
          </Suspense>
        ),
      },
    ],
  },
])
