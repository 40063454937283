import { type PayloadAction } from '@reduxjs/toolkit'
import {
  type IModalWithHistory,
  type IModal,
} from '../../features/modal/IModal'
import { activeChipAiObservable$ } from '../../../modules/dashboard/observables/activeChipAiObservable'

const ModalReducers = {
  showModal: (state: IModalWithHistory, action: PayloadAction<IModal>) => {
    state.isVisible = action.payload.isVisible
    state.content = action.payload.content
    state.props = action.payload.props
    state.allowCloseFromOutside = action.payload.allowCloseFromOutside ?? true
  },

  addModalToHistory: (
    state: IModalWithHistory,
    action: PayloadAction<IModalWithHistory>,
  ) => {
    const { history, ...rest } = action.payload
    state.history.push(rest)
  },

  backOneModal: (state: IModalWithHistory) => {
    state.isVisible = true
    state.content = state.history[state.history.length - 1].content
    state.props = state.history[state.history.length - 1].props
    state.allowCloseFromOutside =
      state.history[state.history.length - 1].allowCloseFromOutside
    state.history.pop()
  },

  hideModal: (state: IModalWithHistory) => {
    activeChipAiObservable$.setSubject(null)
    state.isVisible = false
    state.content = null
    state.history = []
  },
}

export default ModalReducers
