import { createSlice } from '@reduxjs/toolkit'
import { asideReducer } from '../../reducers/aside/AsideReducers'
import { type IAsideWithHistory } from './IAside'

const initialState: IAsideWithHistory = {
  title: '',
  content: null,
  isVisible: false,
  isLoading: false,
  componentProps: null,
  aiChipId: null,
  history: [],
}

export const asideSlice = createSlice({
  name: 'aside',
  initialState,
  reducers: asideReducer,
})

export const {
  showAside,
  hideAside,
  toggleAside,
  loadingAside,
  addAsideToHistory,
  resetAsideHistoy,
  backOneAside,
} = asideSlice.actions
export default asideSlice.reducer
