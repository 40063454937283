import { type PayloadAction } from '@reduxjs/toolkit'
import {
  type IChatHistoryItemOptional,
  type IChatHistoryItem,
  type IChatsHistory,
} from '../../../features/chatbot/chats-history/IChatsHistory'
import {
  type IChatbotMessageMetadata,
  type IChatbotMessage,
} from '../../../../core/components/chatbot/message/interfaces/IChatbotMessage'
import { mergeMetadata } from '../../../../modules/dashboard/pages/chat/consultation/detail/hooks/useDetailConsultation'
import { chatsHistoryFilters } from './filters/ChatsHistoryFilters'

export const chatsHistoryReducers = {
  refreshChatsHistory: (
    state: IChatsHistory,
    action: PayloadAction<IChatsHistory>,
  ) => {
    return [...state, ...action.payload]
  },

  addNewChatToHistory: (
    state: IChatsHistory,
    action: PayloadAction<IChatHistoryItem>,
  ) => {
    state.unshift(action.payload)
  },

  updateChatInHistory: (
    state: IChatsHistory,
    action: PayloadAction<{
      uuid: string
      newInfo: IChatHistoryItemOptional
    }>,
  ) => {
    const newState = state.map((item) =>
      item.uuid === action.payload.uuid
        ? {
            ...item,
            ...action.payload.newInfo,
          }
        : item,
    )
    return newState
  },

  addMessageInChatHistory: (
    state: IChatsHistory,
    action: PayloadAction<{
      uuid: string
      message: IChatbotMessage
    }>,
  ) => {
    const newState = state.map((item) =>
      item.uuid === action.payload.uuid
        ? {
            ...item,
            messages: [...item.messages, action.payload.message],
          }
        : item,
    )
    return newState
  },

  updateMessageInChatHistory: (
    state: IChatsHistory,
    action: PayloadAction<{
      chatUuid: string
      messageUuid: string
      chunk?: string
      metadata?: IChatbotMessageMetadata
      isError?: boolean
    }>,
  ) => {
    let newState = state
    try {
      newState = state.map((chat) =>
        chat.uuid === action.payload.chatUuid
          ? {
              ...chat,
              messages: chat.messages.map((message) =>
                message.uuid === action.payload.messageUuid
                  ? {
                      ...message,
                      prompt: `${message.prompt}${action.payload.chunk ?? ''}`,
                      metadata: action.payload.metadata
                        ? mergeMetadata(
                            message.metadata,
                            action.payload.metadata,
                          )
                        : message.metadata,
                      isError: action.payload.isError ?? message.isError,
                    }
                  : message,
              ),
            }
          : chat,
      )
    } catch (error) {
      console.error('updateMessageInChatHistory error: ', error)
    }
    return newState
  },

  deleteChatInHistory: (
    state: IChatsHistory,
    action: PayloadAction<string>,
  ) => {
    const newState = state.filter((item) => item.uuid !== action.payload)
    return newState
  },

  setIsLoadingResponse: (
    state: IChatsHistory,
    action: PayloadAction<{
      uuid: string
      isLoading: boolean
    }>,
  ) => {
    const newState = state.map((item) =>
      item.uuid === action.payload.uuid
        ? {
            ...item,
            isLoadingResponse: action.payload.isLoading,
          }
        : item,
    )
    return newState
  },
  ...chatsHistoryFilters,
}
