import { type IFilterCategoryItemFromResponse } from '../../../../redux/features/chatbot/chats-history/filters/IFilters'

export const TopicCategories: IFilterCategoryItemFromResponse[] = [
  { name: 'General', code: 'General' },
  { name: 'Constitucional', code: 'Constitucional' },
  { name: 'Internacional Publico', code: 'Internacional_Publico' },
  { name: 'Internacional Privado', code: 'Internacional_Privado' },
  { name: 'Penal', code: 'Penal' },
  { name: 'Financiero', code: 'Financiero' },
  { name: 'Comercial', code: 'Comercial' },
  { name: 'Disciplinario', code: 'Disciplinario' },
  { name: 'Fiscal', code: 'Fiscal' },
  { name: 'Transicional', code: 'Transicional' },
  {
    name: 'Diversidad, Género e Inclusión',
    code: 'Diversidad_Género_e_Inclusión',
  },
  { name: 'Administrativo', code: 'Administrativo' },
  { name: 'Civil', code: 'Civil' },
  { name: 'Familia', code: 'Familia' },
  { name: 'Sociedades', code: 'Sociedades' },
  { name: 'Laboral', code: 'Laboral' },
  { name: 'Servicios Publicos', code: 'Servicios_Publicos' },
  { name: 'Propiedad Intelectual', code: 'Propiedad_Intelectual' },
  { name: 'Deporte', code: 'Deporte' },
  { name: 'Transporte', code: 'Transporte' },
  { name: 'Extinción de Dominio', code: 'Extinción_de_Dominio' },
  { name: 'Migratorio', code: 'Migratorio' },
  { name: 'Ambiental', code: 'Ambiental' },
  { name: 'MASC', code: 'MASC' },
  { name: 'Electoral', code: 'Electoral' },
  { name: 'Desastres Naturales', code: 'Desastres_Naturales' },
  { name: 'Libertad de Prensa', code: 'Libertad_Prensa' },
  { name: 'Médico', code: 'Médico' },
  { name: 'SRPA', code: 'SRPA' },
  { name: 'Cumplimiento', code: 'Cumplimiento' },
  { name: 'Tributario', code: 'Tributario' },
  { name: 'Notariado', code: 'Notariado' },
  { name: 'Urbano', code: 'Urbano' },
  { name: 'Penal Militar', code: 'Penal_Militar' },
]
