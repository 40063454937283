import { createSlice } from '@reduxjs/toolkit'
import { type ITier } from '../../../modules/auth/interfaces/ITier'
import { tierReducer } from '../../reducers/tier/TierReducer'

const initialState: ITier = {
  expiration_date: '',
  is_active: false,
  stage: null,
}

export const tierSlice = createSlice({
  name: 'tier',
  initialState,
  reducers: tierReducer,
})

export const { setTier } = tierSlice.actions

export default tierSlice.reducer
