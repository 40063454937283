import { type PayloadAction } from '@reduxjs/toolkit'
import {
  type IChatHistoryItem,
  type IChatsHistory,
} from '../../../../features/chatbot/chats-history/IChatsHistory'
import { type ICheckboxListOption } from '../../../../../core/components/field/item/checkbox-list/interfaces/ICheckboxList'
import { INITIAL_CUSTOM_FILTERS_VALUES } from '../../../../features/chatbot/chats-history/filters/InitialCustomFiltersValues'
import { type IFieldItemSelectPropsRequired } from '../../../../../core/components/field/item/select-menu/container/interfaces/IFieldItemSelect'

export const chatsHistoryFilters = {
  // ORGANIZATION
  updateOrganizationSwitch: (
    state: IChatsHistory,
    action: PayloadAction<{
      chatUuid: string
      isOn: boolean | null
      organization: 'arielOrganization' | 'customOrganization'
    }>,
  ): IChatHistoryItem[] => {
    const newState = state.map((chat) => {
      if (
        chat.uuid === action.payload.chatUuid &&
        chat.chat_settings?.filters.custom
      ) {
        return {
          ...chat,
          chat_settings: {
            ...chat.chat_settings,
            filters: {
              ...chat.chat_settings.filters,
              custom: {
                ...chat.chat_settings.filters.custom,
                [action.payload.organization]: {
                  ...chat.chat_settings.filters.custom[
                    action.payload.organization
                  ],
                  isOn: action.payload.isOn,
                },
              },
            },
          },
        }
      }
      return chat
    })

    return newState
  },
  updateYear: (
    state: IChatsHistory,
    action: PayloadAction<{
      chatUuid: string
      yearLimit: 'from' | 'to'
      value: IFieldItemSelectPropsRequired | null
    }>,
  ) => {
    const newState = state.map((chat) => {
      if (
        chat.uuid === action.payload.chatUuid &&
        chat.chat_settings?.filters.custom?.year
      ) {
        const counterYearTo = chat?.chat_settings?.filters?.custom?.year?.to
          ? 1
          : 0
        const counterYearFrom = chat?.chat_settings?.filters?.custom?.year?.from
          ? 1
          : 0
        const counterType =
          chat?.chat_settings?.filters?.custom?.type?.counter ?? 0
        const counterTopic =
          chat?.chat_settings?.filters?.custom?.topic?.counter ?? 0

        return {
          ...chat,
          chat_settings: {
            ...chat.chat_settings,
            filters: {
              ...chat.chat_settings.filters,
              custom: {
                ...chat.chat_settings.filters.custom,
                year: {
                  ...chat.chat_settings.filters.custom?.year,
                  [action.payload.yearLimit]: action.payload.value,
                },
                counterFiltersChosen:
                  (action.payload.value === null ? 0 : 1) +
                  (action.payload.yearLimit === 'from'
                    ? counterYearTo
                    : counterYearFrom) +
                  counterType +
                  counterTopic,
              },
            },
          },
        }
      }
      return chat
    })

    return newState
  },

  // CATEGORIES
  updateCategoryActiveList: (
    state: IChatsHistory,
    action: PayloadAction<{
      chatUuid: string
      categoryName: 'type' | 'topic'
      activeList: ICheckboxListOption[]
    }>,
  ) => {
    const counterCategory = action.payload.activeList?.length ?? 0
    const newState = state.map((chat) => {
      if (
        chat.uuid === action.payload.chatUuid &&
        chat.chat_settings?.filters.custom
      ) {
        const counterYearTo = chat?.chat_settings?.filters?.custom?.year?.to
          ? 1
          : 0
        const counterYearFrom = chat?.chat_settings?.filters?.custom?.year?.from
          ? 1
          : 0
        const counterType =
          chat?.chat_settings?.filters?.custom?.type?.counter ?? 0
        const counterTopic =
          chat?.chat_settings?.filters?.custom?.topic?.counter ?? 0

        return {
          ...chat,
          chat_settings: {
            ...chat.chat_settings,
            filters: {
              ...chat.chat_settings.filters,
              custom: {
                ...chat.chat_settings.filters.custom,
                [action.payload.categoryName]: action.payload.activeList && {
                  activeList: action.payload.activeList,
                  counter: action.payload.activeList.length,
                },
                counterFiltersChosen:
                  counterCategory +
                  (action.payload.categoryName === 'type'
                    ? counterTopic
                    : counterType) +
                  counterYearFrom +
                  counterYearTo,
              },
            },
          },
        }
      }
      return chat
    })

    return newState
  },

  clearFilters: (
    state: IChatsHistory,
    action: PayloadAction<{ chatUuid: string }>,
  ) => {
    const newState = state.map((chat) => {
      if (chat.uuid === action.payload.chatUuid) {
        return {
          ...chat,
          chat_settings: chat.chat_settings && {
            ...chat.chat_settings,
            filters: {
              ...chat.chat_settings.filters,
              custom: INITIAL_CUSTOM_FILTERS_VALUES,
            },
          },
        }
      }
      return chat
    })

    return newState
  },
}
