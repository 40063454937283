import { type PayloadAction } from '@reduxjs/toolkit'
import {
  type IAsideWithHistory,
  type IAside,
} from '../../features/aside/IAside'
import { activeChipAiObservable$ } from '../../../modules/dashboard/observables/activeChipAiObservable'

export const asideReducer = {
  showAside: (state: IAsideWithHistory, action: PayloadAction<IAside>) => {
    state.title = action.payload.title
    state.content = action.payload.content
    state.isVisible = action.payload.isVisible
    state.componentProps = action.payload.componentProps
    state.aiChipId = action.payload.aiChipId
    state.isLoading = action.payload.isLoading
  },

  hideAside: (state: IAsideWithHistory) => {
    activeChipAiObservable$.setSubject(null)
    state.title = ''
    state.content = null
    state.isVisible = false
    state.componentProps = null
    state.aiChipId = null
    state.history = []
  },

  toggleAside: (state: IAsideWithHistory, action: PayloadAction<IAside>) => {
    if (state.isVisible) {
      asideReducer.hideAside(state)
    } else {
      asideReducer.showAside(state, action)
    }
  },

  loadingAside: (state: IAsideWithHistory) => {
    state.isLoading = true
    state.title = ''
    state.content = null
    state.isVisible = true
    state.componentProps = null
    state.aiChipId = null
  },

  addAsideToHistory: (
    state: IAsideWithHistory,
    action: PayloadAction<IAsideWithHistory>,
  ) => {
    const { history, ...rest } = action.payload
    state.history.push(rest)
  },

  resetAsideHistoy: (state: IAsideWithHistory) => {
    state.history = []
  },

  backOneAside: (state: IAsideWithHistory) => {
    activeChipAiObservable$.setSubject(
      state.history[state.history.length - 1].aiChipId,
    )
    state.title = state.history[state.history.length - 1].title
    state.content = state.history[state.history.length - 1].content
    state.isVisible = state.history[state.history.length - 1].isVisible
    state.componentProps =
      state.history[state.history.length - 1].componentProps
    state.aiChipId = state.history[state.history.length - 1].aiChipId
    state.isLoading = state.history[state.history.length - 1].isLoading
    state.history.pop()
  },
}
