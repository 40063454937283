import { DATE_1800_T0_2024 } from '../../../core/components/filters/constants/Date1800To2024'
import { TopicCategories } from '../../../core/components/filters/constants/TopicCategories'
import { TypeCategories } from '../../../core/components/filters/constants/TypeCategories'
import {
  type IFiltersForRequest,
  type IDefaultFilterFromResponse,
} from '../../../redux/features/chatbot/chats-history/filters/IFilters'

// RESPONSE
// CHAT HAS NO MESSAGES -> RETURN DEFAULT FILTERS AND CUSTOM FILTERS IN NULL
// CHAT HAS MESSAGES -> RETURN DEFAULT FILTERS AND CUSTOM FILTERS

export const DEFAULT_FILTERS_FROM_RESPONSE: IDefaultFilterFromResponse = {
  arielOrganization: {
    isOn: true,
    totalDocs: 760,
  },
  customOrganization: {
    isOn: false,
    totalDocs: 0,
  },
  year: {
    list: DATE_1800_T0_2024,
    from: DATE_1800_T0_2024[0],
    to: DATE_1800_T0_2024[DATE_1800_T0_2024.length - 1],
  },
  type: {
    list: TypeCategories,
    activeList: [],
  },
  topic: {
    list: TopicCategories,
    activeList: [],
  },
}

export const CUSTOM_FILTERS_FROM_RESPONSE: IFiltersForRequest | null = null

export const FILTERS_MOCK_RESPONSE: {
  default_filters: IDefaultFilterFromResponse
  custom_filters: IFiltersForRequest | null
} = {
  default_filters: DEFAULT_FILTERS_FROM_RESPONSE,
  custom_filters: CUSTOM_FILTERS_FROM_RESPONSE,
}
