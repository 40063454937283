import { type IFilterCategoryItemFromResponse } from '../../../../redux/features/chatbot/chats-history/filters/IFilters'

export const TypeCategories: IFilterCategoryItemFromResponse[] = [
  { name: 'Jurisprudencia', code: 'Jurisprudencia' },
  { name: 'Doctrina', code: 'Doctrina' },
  { name: 'Constitucional', code: 'Constitucional' },
  { name: 'Legal', code: 'Legal' },
  { name: 'Infralegal', code: 'Infralegal' },
  { name: 'Otros', code: 'Otros' },
]
