import { createSlice } from '@reduxjs/toolkit'
import { type IPaginationChatsHistory } from './IPaginationChatsHistory'
import { paginationChatsHistoryReducers } from '../../../../reducers/chatbot/chats-history/pagination/PaginationChatsHistoryReducers'

export const initialState: IPaginationChatsHistory = {
  counterPages: 0,
  maxPagination: 0,
  chatsPerPage: 15,
}

const paginationChatsHistorySlice = createSlice({
  initialState,
  name: 'paginationChatsHistory',
  reducers: paginationChatsHistoryReducers,
})

export default paginationChatsHistorySlice.reducer
export const { setPaginationChatsHistory } = paginationChatsHistorySlice.actions
