import { type IFilterState } from './IFilters'

export const INITIAL_CUSTOM_FILTERS_VALUES: IFilterState = {
  counterFiltersChosen: 0,
  arielOrganization: { isOn: null },
  customOrganization: { isOn: null },
  year: { from: null, to: null },
  type: {
    counter: 0,
    activeList: [],
  },
  topic: {
    counter: 0,
    activeList: [],
  },
}
