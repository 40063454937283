import { t } from 'i18next'
import { FILTERS_MOCK_RESPONSE } from '../../../../mocks/services/filters/FiltersMock'
import {
  type IFilterCategoryItemFromResponse,
  type IFilterSlice,
} from '../../../../redux/features/chatbot/chats-history/filters/IFilters'
import { type ICheckboxListOption } from '../../field/item/checkbox-list/interfaces/ICheckboxList'
import { type IFieldItemSelectPropsRequired } from '../../field/item/select-menu/container/interfaces/IFieldItemSelect'
import {
  type IHandleCounterInFilters,
  type IMapFiltersFromResponse,
  type IUseMapFiltersFromResponse,
} from './IUseMapFiltersFromResponse'

const useMapFiltersFromResponse = (): IUseMapFiltersFromResponse => {
  const mapFiltersFromReponse = ({
    defaultFiltersFromResponse = FILTERS_MOCK_RESPONSE.default_filters,
    customFiltersFromResponse,
  }: IMapFiltersFromResponse): { FILTERS_MOCK_MAP: IFilterSlice } => {
    const typeListWithAllOption = [
      { name: 'Todas las categorías', code: 'Todas_las_categorías' },
      ...defaultFiltersFromResponse.type.list,
    ]
    const topicListWithAllOption = [
      { name: 'Todas las áreas', code: 'Todas_las_áreas' },
      ...defaultFiltersFromResponse.topic.list,
    ]

    // TYPE
    const defaultTypeCategoriesMap: ICheckboxListOption[] = []
    const customTypeCategoriesActive: ICheckboxListOption[] = []
    const defaultTypeCategoriesActive: ICheckboxListOption[] = []

    typeListWithAllOption.forEach(
      (category: IFilterCategoryItemFromResponse) => {
        defaultTypeCategoriesMap.push({
          id: category.code,
          name: category.name,
        })

        if (customFiltersFromResponse?.category?.includes(category.code)) {
          customTypeCategoriesActive.push({
            id: category.code,
            name: category.name,
          })
        }

        if (category.name === t('CHECKBOX_OPTIONS.ALL_CATEGORIES')) {
          defaultTypeCategoriesActive.push({
            id: category.code,
            name: category.name,
          })
        }
      },
    )

    // TOPIC
    const defaultTopicCategoriesMap: ICheckboxListOption[] = []
    const customTopicCategoriesActive: ICheckboxListOption[] = []
    const defaultTopicCategoriesActive: ICheckboxListOption[] = []

    topicListWithAllOption.forEach(
      (category: IFilterCategoryItemFromResponse) => {
        defaultTopicCategoriesMap.push({
          id: category.code,
          name: category.name,
        })

        if (customFiltersFromResponse?.keywords?.includes(category.code)) {
          customTopicCategoriesActive.push({
            id: category.code,
            name: category.name,
          })
        }

        if (category.name === t('CHECKBOX_OPTIONS.ALL_TOPICS')) {
          defaultTopicCategoriesActive.push({
            id: category.code,
            name: category.name,
          })
        }
      },
    )

    let defaultYearFrom: IFieldItemSelectPropsRequired = { id: 0, text: '' }
    let defaultYearTo: IFieldItemSelectPropsRequired = { id: 0, text: '' }
    let customYearFrom: IFieldItemSelectPropsRequired | null = null
    let customYearTo: IFieldItemSelectPropsRequired | null = null

    const yearListMap = defaultFiltersFromResponse.year.list.map((year) => {
      if (year === defaultFiltersFromResponse.year.from) {
        defaultYearFrom = {
          id: year,
          text: String(year),
        }
      }
      if (year === defaultFiltersFromResponse.year.to) {
        defaultYearTo = {
          id: year,
          text: String(year),
        }
      }
      if (year === customFiltersFromResponse?.year_from) {
        customYearFrom = {
          id: year,
          text: String(year),
        }
      }
      if (year === customFiltersFromResponse?.year_to) {
        customYearTo = {
          id: year,
          text: String(year),
        }
      }
      return {
        id: year,
        text: String(year),
      }
    })

    const handleCountersInFilters = (): IHandleCounterInFilters => {
      let counterFiltersChosen = 0
      let counterType = 0
      let counterTopic = 0

      if (
        customFiltersFromResponse !== null &&
        customFiltersFromResponse instanceof Object &&
        Object.keys(customFiltersFromResponse).length > 0
      ) {
        if (
          customFiltersFromResponse.year_from !== undefined &&
          customFiltersFromResponse.year_from !==
            defaultFiltersFromResponse.year.from
        ) {
          counterFiltersChosen += 1
        }
        if (
          customFiltersFromResponse.year_to !== undefined &&
          customFiltersFromResponse.year_to !==
            defaultFiltersFromResponse.year.to
        ) {
          counterFiltersChosen += 1
        }

        if (customFiltersFromResponse.category !== undefined) {
          counterType = customFiltersFromResponse.category.length
          counterFiltersChosen += counterType
        }

        if (customFiltersFromResponse.keywords !== undefined) {
          counterTopic = customFiltersFromResponse.keywords.length
          counterFiltersChosen += counterTopic
        }
      }

      return {
        counterFiltersChosen,
        counterType,
        counterTopic,
      }
    }

    const { counterFiltersChosen, counterType, counterTopic } =
      handleCountersInFilters()

    const FILTERS_MOCK_MAP: IFilterSlice = {
      default: {
        counterFiltersChosen,
        arielOrganization: defaultFiltersFromResponse.arielOrganization,
        customOrganization: defaultFiltersFromResponse.customOrganization,
        year: {
          list: yearListMap,
          reversedList: [...yearListMap.reverse()],
          from: defaultYearFrom,
          to: defaultYearTo,
        },
        type: {
          list: defaultTypeCategoriesMap,
          activeList: defaultTypeCategoriesActive,
          counter: counterType,
        },
        topic: {
          list: defaultTopicCategoriesMap,
          activeList: defaultTopicCategoriesActive,
          counter: counterTopic,
        },
      },
      custom: {
        counterFiltersChosen,
        arielOrganization: {
          isOn: customFiltersFromResponse?.search_on_public ?? null,
        },
        customOrganization: {
          isOn: customFiltersFromResponse?.search_on_company ?? null,
        },
        year: {
          from: customYearFrom,
          to: customYearTo,
        },
        type: {
          activeList:
            customTypeCategoriesActive.length > 0
              ? customTypeCategoriesActive
              : [],
          counter: counterType,
        },
        topic: {
          activeList:
            customTopicCategoriesActive.length > 0
              ? customTopicCategoriesActive
              : [],
          counter: counterTopic,
        },
      },
    }
    return {
      FILTERS_MOCK_MAP,
    }
  }

  return {
    mapFiltersFromReponse,
  }
}

export default useMapFiltersFromResponse
