import { type IMessage } from './interfaces/IMessage'
import {
  BiSolidInfoCircle,
  BiSolidError,
  BiSolidCheckCircle,
  BiSolidXCircle,
} from 'react-icons/bi'
import { MessageTypes } from './enums/MessageTypes'
import MessageItem from './item/MessageItem'
import './Message.scss'

const Message = ({
  type,
  title,
  description,
  list,
}: IMessage): React.ReactNode => {
  return (
    <div className={`message message--${type ?? 'INFO'}`}>
      {type === undefined && (
        <BiSolidInfoCircle size="22px" color="var(--Primary-500)" />
      )}
      {type === MessageTypes.INFO && (
        <BiSolidInfoCircle size="22px" color="var(--Primary-500)" />
      )}
      {type === MessageTypes.ERROR && (
        <BiSolidXCircle size="22px" color="var(--Red-600)" />
      )}
      {type === MessageTypes.SUCCESS && (
        <BiSolidCheckCircle size="22px" color="var(--Green-600)" />
      )}
      {type === MessageTypes.WARNING && (
        <BiSolidError size="22px" color="var(--Yellow-500)" />
      )}
      <div className="message__texts">
        {title && <div className="message__title">{title}</div>}
        {description && (
          <div className="message__description">{description}</div>
        )}
        {list && (
          <ul className="message__list">
            {list.map((item) => (
              <MessageItem text={item.text} key={item.id} />
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default Message
