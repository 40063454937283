import httpSecure from './AxiosSecureAbstractInstance'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { getResponseType } from '../enums/GetResponseType'
import { AUTH_COOKIE_NAME } from '../../modules/auth/cookies/authCookiesKeys'
import {
  type IApiSecureArielAxiosHttpsClient,
  type IDeleteProps,
  type IGetProps,
  type IPostProps,
  type IPutProps,
} from './interfaces/IApiSecureArielAxiosHttpClient'

const cookies = new Cookies()

const useApiSecureAxiosHttpClient = (): IApiSecureArielAxiosHttpsClient => {
  const navigate = useNavigate()

  const redirectLogout = (error: any): void => {
    console.error('Invalid auth --->', error)
    navigate('/auth/logout')
  }

  const get = async ({
    path,
    customConfig,
    responseType = getResponseType.DATA,
  }: IGetProps): Promise<any> => {
    let config: any = {}

    if (cookies.get(AUTH_COOKIE_NAME)) {
      config.headers = {
        Authorization: `Bearer ${cookies.get(AUTH_COOKIE_NAME)}`,
      }
    }

    if (customConfig) {
      config = {
        ...customConfig,
        headers: {
          ...config.headers,
          ...(customConfig.headers ?? {}),
        },
      }
    }

    return await new Promise((resolve, reject) => {
      httpSecure
        .get(path, config)
        .then((response) => {
          if (responseType === getResponseType.HEADERS_AND_DATA) {
            resolve({
              data: response.data,
              headers: response.headers,
            })
          } else {
            resolve(response.data)
          }
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            redirectLogout(error.response)
          }
          reject(error)
        })
    })
  }

  const post = async ({
    path,
    payload = {},
    customConfig,
  }: IPostProps): Promise<any> => {
    let config: any = {}
    if (cookies.get(AUTH_COOKIE_NAME)) {
      config.headers = {
        Authorization: `Bearer ${cookies.get(AUTH_COOKIE_NAME)}`,
      }
    }

    if (customConfig) {
      config = {
        ...customConfig,
        headers: {
          ...config.headers,
          ...customConfig.headers,
        },
      }
    }
    return await new Promise((resolve, reject) => {
      httpSecure
        .post(path, payload, config)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            redirectLogout(error.response)
          }
          reject(error)
        })
    })
  }
  const put = async ({
    path,
    payload,
    customConfig,
  }: IPutProps): Promise<any> => {
    let config: any = {}
    if (cookies.get(AUTH_COOKIE_NAME)) {
      config.headers = {
        Authorization: `Bearer ${cookies.get(AUTH_COOKIE_NAME)}`,
      }
    }

    if (customConfig) {
      config = {
        ...customConfig,
        headers: {
          ...config.headers,
          ...customConfig.headers,
        },
      }
    }

    return await new Promise((resolve, reject) => {
      httpSecure
        .put(path, payload, config)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            redirectLogout(error.response)
          }
          reject(error)
        })
    })
  }

  const deleteRequest = async ({ path }: IDeleteProps): Promise<any> => {
    const config: any = {}
    if (cookies.get(AUTH_COOKIE_NAME)) {
      config.headers = {
        Authorization: `Bearer ${cookies.get(AUTH_COOKIE_NAME)}`,
      }
    }

    return await new Promise((resolve, reject) => {
      httpSecure
        .delete(path, config)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            redirectLogout(error.response)
          }
          reject(error)
        })
    })
  }

  return {
    get,
    post,
    put,
    deleteRequest,
  }
}

export default useApiSecureAxiosHttpClient
