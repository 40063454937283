import { type PayloadAction } from '@reduxjs/toolkit'
import { type IPaginationChatsHistory } from '../../../../features/chatbot/chats-history/pagination/IPaginationChatsHistory'

export const paginationChatsHistoryReducers = {
  setPaginationChatsHistory: (
    state: IPaginationChatsHistory,
    action: PayloadAction<Record<string, number>>,
  ) => {
    return {
      ...state,
      ...action.payload,
    }
  },
}
