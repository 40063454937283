import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { ES_TRANSLATIONS } from './assets/i18n/es'

const resources = {
  es: {
    translation: ES_TRANSLATIONS,
  },
}

void i18n.use(initReactI18next).init({
  resources,
  lng: 'es',
  fallbackLng: 'es',
  interpolation: {
    escapeValue: false,
  },
} as any)

export default i18n
