export enum ToastTypes {
  INFORMATION = 'INFORMATION',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  PRECAUTION = 'PRECAUTION',
}

export enum ToastCallbackTypes {
  SEE = 'SEE',
  RETRY = 'RETRY',
}
