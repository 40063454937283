import { createSlice } from '@reduxjs/toolkit'
import { ChatSettingsReducers } from '../../../reducers/chatbot/chat-settings/ChatSettingsReducers'
import { type IChatSettings } from '../chats-history/IChatsHistory'

const initialState: IChatSettings[] = []

const chatSettingsState = createSlice({
  initialState,
  name: 'chatSettings',
  reducers: ChatSettingsReducers,
})

export default chatSettingsState.reducer
export const { setChatSettings } = chatSettingsState.actions
