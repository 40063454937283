import { createSlice } from '@reduxjs/toolkit'
import { type IModalWithHistory } from './IModal'
import ModalReducers from '../../reducers/modal/ModalReducers'

const initialState: IModalWithHistory = {
  content: null,
  isVisible: false,
  allowCloseFromOutside: true,
  history: [],
}

const modalSlice = createSlice({
  initialState,
  name: 'modal',
  reducers: ModalReducers,
})

export default modalSlice.reducer
export const { showModal, addModalToHistory, backOneModal, hideModal } =
  modalSlice.actions
