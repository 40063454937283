import { createSlice } from '@reduxjs/toolkit'
import { type ITranscriptionsTable } from '../../../modules/auth/interfaces/ITranscriptionsTable'
import { TranscriptionsTableReducers } from '../../reducers/transcriptions/TranscriptionsTableReducers'

const initialState: ITranscriptionsTable = {
  isInitialRequestDone: false,
  areTranscriptionsLoading: true,
  transcriptionsTotal: 0,
  transcriptionsByPages: {},
  pendingOrProcessingList: [],
  currentPage: 0,
  transcriptionsDetail: [],
}

const transcriptionsTableSlice = createSlice({
  initialState,
  name: 'transcriptionsTable',
  reducers: TranscriptionsTableReducers,
})

export const {
  setTrancriptionsByPage,
  addTranscription,
  removeTranscription,
  updateTranscriptionName,
  clearTranscriptionsList,
  setPendingOrProcessingList,
  setTranscriptionsTotal,
  setCurrentPage,
  updateIsInitialRequestDone,
  setAreTranscriptionsLoading,
  addItemInTranscriptionsDetail,
  addMessagesInTranscriptionsDetail,
  addMessageInTranscriptionsDetail,
  updateTranscriptionInTranscriptionsDetail,
  removeItemInTranscriptionsDetail,
  setIsLoadingResponseFromTranscription,
} = transcriptionsTableSlice.actions
export default transcriptionsTableSlice.reducer
