import { type PayloadAction } from '@reduxjs/toolkit'
import { type ITier } from '../../../modules/auth/interfaces/ITier'

export const tierReducer = {
  setTier: (state: ITier, action: PayloadAction<ITier>) => {
    state.expiration_date = action.payload.expiration_date
    state.is_active = action.payload.is_active
    state.stage = action.payload.stage
  },
}
