import { type PayloadAction } from '@reduxjs/toolkit'
import { type IToast } from '../../../core/components/toast/interfaces/IToast'

export const ToastReducers = {
  showToast: (state: IToast, action: PayloadAction<IToast>) => {
    state.type = action.payload.type
    state.title = action.payload.title
    state.description = action.payload.description
    state.btnLabel = action.payload.btnLabel
    state.cb = action.payload.cb
    state.isVisible = action.payload.isVisible
    state.timeToClose = action.payload.timeToClose
  },
  closeToast: (state: IToast) => {
    state.isVisible = false
    state.timeToClose = 0
  },
}
